@property --p {
  syntax: '<number>';
  inherits: true;
  initial-value: 0;
}

.pie,
.placeholder {
  --p: 20;
  --b: 2.5rem;
  --c: darkred;
  --w: 12.5rem;

  width: var(--w);
  aspect-ratio: 1;
  position: relative;
  display: inline-grid;
  place-content: center;
  font-size: 1.5rem;
  font-weight: bold;
  font-family: sans-serif;
}
.placeholder {
  --p: 100;
}
.pie:before,
.pie:after,
.placeholder::before {
  content: '';
  position: absolute;
  border-radius: 50%;
}
.pie:before {
  inset: 0;
  background:
    radial-gradient(farthest-side, var(--c) 98%, #0000) top/var(--b) var(--b)
      no-repeat,
    conic-gradient(var(--c) calc(var(--p) * 1%), #0000 0);
  -webkit-mask: radial-gradient(
    farthest-side,
    #0000 calc(99% - var(--b)),
    #000 calc(100% - var(--b))
  );
  mask: radial-gradient(
    farthest-side,
    #0000 calc(99% - var(--b)),
    #000 calc(100% - var(--b))
  );
}

@media (forced-colors: active) {
  .pie:before {
    forced-color-adjust: none;
    background: forced-color-adjust;
  }
}
.pie:after {
  inset: calc(50% - var(--b) / 2);
  background: var(--c);
  transform: rotate(calc(var(--p) * 3.6deg))
    translateY(calc(50% - var(--w) / 2));
}
.placeholder:before {
  content: '';
  position: absolute;
  opacity: 0.2;
  inset: 0;
  background:
    radial-gradient(farthest-side, var(--c) 98%, #0000) top/var(--b) var(--b)
      no-repeat,
    conic-gradient(gray calc(var(--p) * 1%), #0000 0);
  -webkit-mask: radial-gradient(
    farthest-side,
    #0000 calc(99% - var(--b)),
    #000 calc(100% - var(--b))
  );
  mask: radial-gradient(
    farthest-side,
    #0000 calc(99% - var(--b)),
    #000 calc(100% - var(--b))
  );
}
.animate {
  animation: none;
}

@media (prefers-reduced-motion: no-preference) {
  .animate {
    animation: p 1s 0.5s both;
  }
}

.no-round:before {
  background-size:
    0 0,
    auto;
}
.no-round:after {
  content: none;
}
@keyframes p {
  from {
    --p: 0;
  }
}
