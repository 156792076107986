/* latin-ext */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/fe0777f1195381cb-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/eafabf029ad39a43-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/c3bc380753a8436c-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/0484562807a97172-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: 'Poppins Fallback';src: local("Arial");ascent-override: 93.62%;descent-override: 31.21%;line-gap-override: 8.92%;size-adjust: 112.16%
}.__className_8481d5 {font-family: 'Poppins', 'Poppins Fallback';font-style: normal
}.__variable_8481d5 {--font-poppins: 'Poppins', 'Poppins Fallback'
}

@property --p {
  syntax: '<number>';
  inherits: true;
  initial-value: 0;
}

.cirlce-graph_pie__Odr0q,
.cirlce-graph_placeholder__4EPCQ {
  --p: 20;
  --b: 2.5rem;
  --c: darkred;
  --w: 12.5rem;

  width: var(--w);
  aspect-ratio: 1;
  position: relative;
  display: inline-grid;
  place-content: center;
  font-size: 1.5rem;
  font-weight: bold;
  font-family: sans-serif;
}
.cirlce-graph_placeholder__4EPCQ {
  --p: 100;
}
.cirlce-graph_pie__Odr0q:before,
.cirlce-graph_pie__Odr0q:after,
.cirlce-graph_placeholder__4EPCQ::before {
  content: '';
  position: absolute;
  border-radius: 50%;
}
.cirlce-graph_pie__Odr0q:before {
  inset: 0;
  background:
    radial-gradient(farthest-side, var(--c) 98%, #0000) top/var(--b) var(--b)
      no-repeat,
    conic-gradient(var(--c) calc(var(--p) * 1%), #0000 0);
  -webkit-mask: radial-gradient(
    farthest-side,
    #0000 calc(99% - var(--b)),
    #000 calc(100% - var(--b))
  );
  mask: radial-gradient(
    farthest-side,
    #0000 calc(99% - var(--b)),
    #000 calc(100% - var(--b))
  );
}

@media (forced-colors: active) {
  .cirlce-graph_pie__Odr0q:before {
    forced-color-adjust: none;
    background: forced-color-adjust;
  }
}
.cirlce-graph_pie__Odr0q:after {
  inset: calc(50% - var(--b) / 2);
  background: var(--c);
  transform: rotate(calc(var(--p) * 3.6deg))
    translateY(calc(50% - var(--w) / 2));
}
.cirlce-graph_placeholder__4EPCQ:before {
  content: '';
  position: absolute;
  opacity: 0.2;
  inset: 0;
  background:
    radial-gradient(farthest-side, var(--c) 98%, #0000) top/var(--b) var(--b)
      no-repeat,
    conic-gradient(gray calc(var(--p) * 1%), #0000 0);
  -webkit-mask: radial-gradient(
    farthest-side,
    #0000 calc(99% - var(--b)),
    #000 calc(100% - var(--b))
  );
  mask: radial-gradient(
    farthest-side,
    #0000 calc(99% - var(--b)),
    #000 calc(100% - var(--b))
  );
}
.cirlce-graph_animate__XA1ee {
  animation: none;
}

@media (prefers-reduced-motion: no-preference) {
  .cirlce-graph_animate__XA1ee {
    animation: cirlce-graph_p__ujq0r 1s 0.5s both;
  }
}

.cirlce-graph_no-round__LCg8S:before {
  background-size:
    0 0,
    auto;
}
.cirlce-graph_no-round__LCg8S:after {
  content: none;
}
@keyframes cirlce-graph_p__ujq0r {
  from {
    --p: 0;
  }
}

.text-box-expandable_icon-add__6bvjA {
  display: block;
}

.text-box-expandable_icon-remove__4Og5g {
  display: none;
}

details[open] .text-box-expandable_icon-add__6bvjA {
  display: none;
}

details[open] .text-box-expandable_icon-remove__4Og5g {
  display: block;
}
