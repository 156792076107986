.icon-add {
  display: block;
}

.icon-remove {
  display: none;
}

details[open] .icon-add {
  display: none;
}

details[open] .icon-remove {
  display: block;
}